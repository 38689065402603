import {Flex, Stack, Container} from '@chakra-ui/layout';
import Image from 'next/image';
import router from 'next/router';
import {TRACK_LINK_ID} from '../../utils/mixpanel';

const socialLinks = [
  {
    link: 'https://www.facebook.com/ShowpieceFB/',
    image: '/assets/facebook.svg',
    id: TRACK_LINK_ID.SOCIAL_FB,
    alt: 'Facebook',
  },
  {
    link: 'https://www.instagram.com/showpiecegram/',
    image: '/assets/instagram.svg',
    id: TRACK_LINK_ID.SOCIAL_IG,
    alt: 'Instagram',
  },
  {
    link: 'https://twitter.com/ShowpieceTweets',
    image: '/assets/twitter.svg',
    id: TRACK_LINK_ID.SOCIAL_TW,
    alt: 'Twitter',
  },
];

const SocialButtons = () => {
  return (
    <Stack justifyContent="center" direction="row" spacing="2" w="100%">
      {socialLinks.map((socialLink) => (
        <a
          className={socialLink.id}
          key={socialLink.link}
          href={socialLink.link}
          target="_blank"
          rel="noreferrer"
          aria-label={socialLink.alt}
        >
          <Flex>
            <Image
              height="32"
              width="32"
              src={socialLink.image}
              alt={`${socialLink.alt} Logo`}
            />
          </Flex>
        </a>
      ))}
    </Stack>
  );
};

export const Footer: React.FC = () => {
  return (
    <Flex bg="superlight" pb="18px" as="footer">
      <Container maxW="container.xl">
        <Flex
          pt={{base: '40px', md: '73px'}}
          pb={{base: '20px', md: '89px'}}
          direction={{base: 'column', md: 'row'}}
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          color="charcoal"
        >
          <Flex
            w={{base: '100%', md: '40'}}
            justifyContent={{base: 'center', md: 'flex-start'}}
          >
            <Image
              height="50"
              width="180"
              src="/assets/logo-dark.svg"
              alt="Showpiece Logo"
            />
          </Flex>

          <Flex
            mt={{base: '5', md: '0'}}
            mb={{base: 2, md: 0}}
            textStyle="body4"
            textAlign="center"
            display={{base: 'flex', md: 'none'}}
            onClick={() => router.push('/terms-and-conditions')}
          >
            Terms &amp; Conditions
          </Flex>
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            width={{base: '30%', lg: '25%', xl: '20%'}}
          >
            <Flex
              mt={{base: '3'}}
              mb={{base: '5', md: '2'}}
              textStyle="body4"
              direction="row"
              w="100%"
              justifyContent="space-between"
            >
              <Flex
                display={{base: 'none', md: 'flex'}}
                as="button"
                onClick={() => router.push('/terms-and-conditions')}
              >
                Terms &amp; Conditions
              </Flex>
              <Flex as="button" onClick={() => router.push('/privacy-policy')}>
                Privacy
              </Flex>
              <Flex>
                {' '}
                <a href="mailto:info@showpiece.com">Contact</a>
              </Flex>
            </Flex>
          </Flex>

          <Flex w={{md: '40'}}>
            <SocialButtons />
          </Flex>
        </Flex>
        <Flex
          justifyContent={{base: 'center'}}
          flex={1}
          textStyle="body5"
          color="tone3"
        >
          © Copyright Showpiece Technologies Ltd. {new Date().getFullYear()}
        </Flex>
      </Container>
    </Flex>
  );
};
