import {Flex} from '@chakra-ui/react';
import Image from 'next/image';

export interface SocialBadgeProps {
  linkUrl: string;
  linkTitle: string;
  socialIcon: {
    url: string;
  };
}

export const SocialBadge: React.FC<SocialBadgeProps> = ({
  linkUrl,
  linkTitle,
  socialIcon,
}) => {
  return (
    <a
      key={linkUrl}
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      aria-label={linkTitle}
    >
      <Flex>
        <Image
          height="32"
          width="32"
          src={socialIcon.url}
          alt={`${linkTitle} Logo`}
        />
      </Flex>
    </a>
  );
};
