import {Box, BoxProps} from '@chakra-ui/react';
import {TitleMetaLinkTag} from 'react-datocms';
import {NextSeo} from 'next-seo';
import React from 'react';
import {NavBar, NavVariant} from './nav-bar';
import {Footer} from './footer';
import {DecorativeShapes} from './decorative-shapes';
import {mapSeoTags} from '../../utils/utils';

export const PageLayout: React.FC<{
  firstName?: string;
  isCheckout?: boolean;
  additionalContainerStyles?: BoxProps;
  additionalContentStyles?: BoxProps;
  hideShapes?: boolean;
  noBg?: boolean;
  onClick?: () => void;
  variant?: NavVariant;
  FooterBanner?: React.ReactNode;
  hideNavBar?: boolean;
  hideOverflow?: boolean;
  CustomNavbar?: JSX.Element;
  hideFooter?: boolean;
  title?: string;
  seo?: TitleMetaLinkTag[];
  children: React.ReactNode;
}> = (props) => {
  const {CustomNavbar} = props;
  const bg = props.noBg
    ? {}
    : {
        bg: 'white',
      };
  const {
    title,
    description,
    ogTitle,
    ogDescription,
    ogType,
    ogLocale,
    ogImage,
    ogImageAlt,
    twitterCard,
  } = mapSeoTags(props.seo ?? []);

  return (
    <Box
      {...bg}
      overflow={props.hideOverflow ? 'hidden' : 'visible'}
      {...props.additionalContainerStyles}
    >
      <NextSeo
        title={title}
        description={description}
        additionalMetaTags={[
          {
            name: 'twitter:card',
            content: twitterCard,
          },
        ]}
        openGraph={{
          title: ogTitle,
          description: ogDescription,
          images: [
            {
              url: ogImage,
              alt: ogImageAlt,
            },
          ],
          site_name: 'Showpiece',
          type: ogType,
          locale: ogLocale,
        }}
      />

      {CustomNavbar ? (
        <>{CustomNavbar}</>
      ) : (
        <NavBar
          isCheckout={props.isCheckout}
          username={props.firstName}
          onClick={props.onClick}
          variant={props.variant}
          hideNavBarActions={props.hideNavBar}
        />
      )}
      {props.hideShapes ? null : (
        <Box display={{base: 'none', md: 'block'}}>
          <DecorativeShapes />
        </Box>
      )}
      {/* min height of header and footer for large devices is 284px */}
      <Box
        position="relative"
        minH="calc(100vh - 284px)"
        pt={{base: '71px', md: '88px'}}
        pb={{base: '71px', md: '88px'}}
        {...props.additionalContentStyles}
      >
        {props.children}
      </Box>
      {props.hideFooter ? null : (
        <Box position="relative">
          <Footer />
        </Box>
      )}
      {props.FooterBanner && props.FooterBanner}
    </Box>
  );
};
