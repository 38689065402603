import {Text, Flex, Image, Center, Box} from '@chakra-ui/react';

interface SisterCompanyLogosProps {
  title: string;
  logos: {url: string; alt: string}[];
  backgroundColour?: string;
}
export const SisterCompanyLogos: React.FC<SisterCompanyLogosProps> = ({
  title,
  logos,
  backgroundColour,
}) => {
  return (
    <Box
      backgroundColor={backgroundColour}
      px={{base: 0, lg: '74px'}}
      py="56px"
    >
      <Flex flexDirection="column">
        <Text color="darkGrey" textAlign="center" mb="2" textStyle="h6">
          {title}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        maxW="900px"
        margin="0 auto"
      >
        {logos.map((logo) => (
          <Center
            key={logo.url}
            maxWidth="50%"
            px={{base: 2, md: 4}}
            pb={{base: '10px', md: 0}}
            display={{
              base:
                logo.alt === 'scalextrics logo' || logo.alt === 'corgi logo'
                  ? 'none'
                  : 'block',
              md: 'block',
            }}
          >
            <Image maxH="128px" src={logo.url} alt={logo.alt} />
          </Center>
        ))}
      </Flex>
    </Box>
  );
};
