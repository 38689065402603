// eslint-disable-next-line no-restricted-imports
import {Text, Box, Flex, Stack, Button} from '@chakra-ui/react';
import {ResponsiveImageType} from 'react-datocms';
import router from 'next/router';
import {DatoCmsImage} from '../datocms/image';
import {SocialBadge, SocialBadgeProps} from '../global/social-badge';

interface CenteredImageWithTextProps {
  preTitle: string;
  title: string;
  body: string;
  backgroundColor: 'white' | 'superlight';
  heroImage?: ResponsiveImageType;
  imagePosition: 'left' | 'right';
  socials: SocialBadgeProps[];
  primaryCtaUrl?: string;
  primaryCtaText?: string;
}

export const CenteredImageWithText: React.FC<CenteredImageWithTextProps> = ({
  preTitle,
  title,
  body,
  backgroundColor,
  heroImage,
  imagePosition,
  socials,
  primaryCtaUrl,
  primaryCtaText,
}) => {
  return (
    <Box
      w="100%"
      m="0 auto"
      px={{base: '16px', md: '24px'}}
      py={{base: '32px', md: '72px'}}
      bg={backgroundColor}
    >
      <Box maxW="975px" margin="0 auto">
        <Flex
          direction={{
            base: 'column',
            md: imagePosition === 'right' ? 'row' : 'row-reverse',
          }}
          justify="center"
          align="center"
        >
          <Flex
            direction="column"
            maxW="460px"
            mr={imagePosition === 'right' ? {md: '120px'} : 0}
            ml={imagePosition === 'left' ? {md: '120px'} : 0}
            flex={1}
          >
            <Text as="h6" textStyle="h6">
              {preTitle}
            </Text>
            <Text
              as="h3"
              textStyle={{base: 'h5', md: 'h3'}}
              mb={{base: '8px', md: '16px'}}
            >
              {title}
            </Text>
            <Text
              textStyle="body2"
              display={{base: 'none', md: 'block'}}
              mb="24px"
            >
              {body}
            </Text>
            <Stack
              justifyContent="start"
              direction="row"
              spacing="2"
              w="100%"
              mb={{base: '24px', md: 0}}
            >
              {socials.map((socialLink) => (
                <SocialBadge
                  linkTitle={socialLink.linkTitle}
                  linkUrl={socialLink.linkUrl}
                  socialIcon={socialLink.socialIcon}
                />
              ))}
            </Stack>
            {primaryCtaUrl && primaryCtaText && (
              <Box display={{base: 'none', md: 'flex'}}>
                <Button
                  width="135px"
                  colorScheme="aqua"
                  mb="8px"
                  onClick={() =>
                    primaryCtaUrl ? router.push(primaryCtaUrl) : undefined
                  }
                >
                  {primaryCtaText}
                </Button>
              </Box>
            )}
          </Flex>
          <Box mb={{base: '24px'}} maxW="360px" flex={1}>
            <DatoCmsImage
              responsiveImage={heroImage}
              style={{borderRadius: '12px'}}
            />
          </Box>
          <Text
            textStyle="body2"
            display={{base: 'block', md: 'none'}}
            maxW="460px"
          >
            {body}
          </Text>
          {primaryCtaUrl && primaryCtaText && (
            <Box
              display={{base: 'flex', md: 'none'}}
              w="100%"
              pt={{base: '16px'}}
            >
              <Button
                width="135px"
                colorScheme="aqua"
                mb="8px"
                onClick={() =>
                  primaryCtaUrl ? router.push(primaryCtaUrl) : undefined
                }
              >
                {primaryCtaText}
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
