import React from 'react';
import {Box} from '@chakra-ui/react';

const BasicShape = ({
  bgImage,
  top,
  pos,
  right,
  left,
}: {
  bgImage: string;
  top: any;
  pos: any;
  right: any;
  left: any;
}) => {
  return (
    <Box
      pos="absolute"
      top={top}
      right={right}
      left={left}
      width="30vw"
      height="15vw"
      maxW="485px"
      maxH="240px"
    >
      <Box
        width="100%"
        height="100%"
        bgImage={bgImage}
        bgPos={pos}
        bgRepeat="no-repeat"
        bgSize="contain"
      />
    </Box>
  );
};

interface DecorativeShapeProps {
  top?: {base: number; md: number};
  sides?: {base: number | string; md: number | string};
  hideRightShapes?: boolean;
}

const DecorativeShapes: React.FC<DecorativeShapeProps> = ({
  top = {base: 150, md: 150},
  sides = {base: '0%', md: '0%'},
  hideRightShapes,
}: DecorativeShapeProps) => {
  return (
    <Box position="absolute" top="0" width="100vw">
      <BasicShape
        bgImage="url('/assets/shape-left.svg')"
        top={top}
        left={sides}
        right="inherit"
        pos="left"
      />
      {!hideRightShapes && (
        <BasicShape
          bgImage="url('/assets/shape-right.svg')"
          top={top}
          right={sides}
          pos="right"
          left="inherit"
        />
      )}
    </Box>
  );
};

export {DecorativeShapes};
