import {Image as DatoCMSImage, ResponsiveImageType} from 'react-datocms';
import NextImage from 'next/image';

export const DatoCmsImage: React.FC<{
  format?: string;
  url?: string;
  title?: string;
  style?: React.CSSProperties;
  pictureStyle?: React.CSSProperties;
  preloadImage?: boolean;
  responsiveImage?: ResponsiveImageType | undefined | null;
  layout?: 'intrinsic' | 'fixed' | 'responsive' | 'fill';
  objectFit?: React.CSSProperties['objectFit'];
  objectPosition?: React.CSSProperties['objectPosition'];
}> = ({
  responsiveImage,
  url,
  style,
  preloadImage,
  format,
  pictureStyle,
  title,
  layout,
  objectFit,
  objectPosition,
}) => {
  if (format === 'svg' && url) {
    return <NextImage src={url} layout="fill" alt={title} />;
  }

  return (
    <DatoCMSImage
      lazyLoad={preloadImage !== true}
      style={style}
      pictureStyle={pictureStyle}
      data={responsiveImage as ResponsiveImageType}
      layout={layout}
      objectFit={objectFit}
      objectPosition={objectPosition}
    />
  );

  return null;
};
